var SMWidget = {
	el: null,
	url: null,
	imgpath: '/assets/widget/assets',
	pts: null,
	pts_ind: null,
	apkt: -1,
	tune: false,
	map: null,
	provider: null,
	searchMark: null,
	listMax: 380,
	listLen: 30,
	icon: null,
	icon_b: null,
	type_i: ['K'],
	type_t: ['K'],
	mapType: 'OSM',
	markerClusters: null,
	mks: [],
	mks_i: [],
	selected: null,
	googleKey: 'test',
	selectCb: null,
	loadCb: null,
	readyCb: null,
	type: ['K'],
	type_st: ['K'],
	cod: 0,
	initialAddress: '',
	map_y: 380
};

var smjq;
SMWidget['init'] = function (el, url, opts) {
	smjq = $;
	if (SMWidget.el != null) return;
	SMWidget.el = el;
	SMWidget.url = url;
	if (opts.mapType) SMWidget.mapType = opts.mapType;
	if (opts.googleKey) SMWidget.googleKey = opts.googleKey;
	if (opts.selectCb) SMWidget.selectCb = opts.selectCb;
	if (opts.loadCb) SMWidget.loadCb = opts.loadCb;
	if (opts.readyCb) SMWidget.readyCb = opts.readyCb;
	if (opts.type) SMWidget.type = opts.type;
	if (opts.cod) SMWidget.cod = opts.cod;
	if (opts.initialAddress) SMWidget.initialAddress = opts.initialAddress;
	if (opts.map_y) SMWidget.map_y = opts.map_y;
	if (opts.imgpath) SMWidget.imgpath = opts.imgpath;

	var r2 = '<div class="searchBar"><input placeholder="Wpisz miasto lub kod" type="text" id="sm_widget_inp"/><button type="submit" id="sm_widget_but_s">Pokaż</button><div class="searchBar__location"id="sm_widget_lok_link">Lokalizuj mnie</div></div>';
	var r3 = '<div class="sidebar" id="sm_widget_panel_div"></div><div class="map" id="sm_widget_map"></div>';
	smjq('#' + SMWidget.el).html('<img id="loading-smmap" src="' + SMWidget.imgpath + '/busy.gif"/><div id="progress"><div id="progress-bar"></div></div>' + r2 + r3 + '');
//	smjq('#sm_widget_panel_div').height(SMWidget.map_y);
	smjq('#sm_widget_map').height(SMWidget.map_y);
	SMWidget.showButtons();
	SMWidget.map = L.map('sm_widget_map', {
		center: [52.229823, 21.011721],
		zoom: 12
	});
	L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
		attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		maxZoom: 19
	}).addTo(SMWidget.map);
	smjq('#sm_widget_lok_link').click(function () {
		//SMWidget.map.locate();	// Nie działa
		SMWidget.getLocation();
	});
	smjq('#sm_widget_all_link').click(function () {
		SMWidget.buttonAll();
	});
	SMWidget.markerClusters = L.markerClusterGroup({
		showCoverageOnHover: false,
		chunkedLoading: true,
		chunkProgress: SMWidget.updateProgressBar
	});
	SMWidget.show();
	if(SMWidget.loadCb) SMWidget.loadCb();
	SMWidget.loadPts(SMWidget.init8);

	var OpenStreetMapProvider = window.GeoSearch.OpenStreetMapProvider;
	SMWidget.provider = new OpenStreetMapProvider();

	smjq('#sm_widget_but_s').click(function (event) {
		event.preventDefault();
		SMWidget.search();
	});
	
	smjq('#sm_widget_inp').keypress(function (event) {
	    if(event.which == 13) {
			SMWidget.search();
			return false;
	    }
	 });
	
};

SMWidget['reload'] = function (opts) {
	if (opts.cod) SMWidget.cod = opts.cod;
	SMWidget.pts = null;
	SMWidget.pts_ind = null;
	SMWidget.mks = [];
	SMWidget.mks_i = [];
	if (SMWidget.selectCb) SMWidget.selectCb(null);
	SMWidget.apkt = -1;
	SMWidget.markerClusters.clearLayers();
	SMWidget.show();
	SMWidget.loadPts(SMWidget.init8);	
}

SMWidget['init8'] = function (resp) {
	if (resp.error) alert('Błąd pobierania danych');
	else {
		SMWidget.pts = resp.pts;
		SMWidget.map.addLayer(SMWidget.markerClusters);
		SMWidget.icon = L.icon({
			iconUrl: SMWidget.imgpath + '/marker-icon-red.png',
			className: 'sm_widget_icon',
			iconSize: [25, 41],
			iconAnchor: [12, 40],
			popupAnchor: [0, -28]
		});
		SMWidget.icon_b = L.icon({
			iconUrl: SMWidget.imgpath + '/marker-icon-red.png',
			className: 'sm_widget_icon_b',
			iconSize: [25, 41],
			iconAnchor: [12, 40],
			popupAnchor: [0, -28]
		});
		SMWidget.showPts();
		SMWidget.map.on('popupopen', SMWidget.popopen);
		SMWidget.map.on('popupclose', SMWidget.popclose);
		SMWidget.map.on('resize', SMWidget.loadList);
		SMWidget.map.on('zoomend', SMWidget.loadList);
		SMWidget.map.on('moveend', SMWidget.loadList);
		//		SMWidget.map.on('resize', SMWidget.loadList);
		if (SMWidget.initialAddress != '') {
			if ((SMWidget.pts_ind != null) && (SMWidget.pts_ind[SMWidget.initialAddress.toUpperCase()] != null)) {
				var p = SMWidget.pts_ind[SMWidget.initialAddress.toUpperCase()];
				SMWidget.map.setView([p.la, p.lo], 15);
			} else {
				SMWidget.provider.search({
					query: SMWidget.initialAddress
				}).then((results) => {
					if (results.length != 0) {
						var r = results[0];
						SMWidget.tune = true;
						SMWidget.map.setView([r.y, r.x], 15);
					}
				})
			}
		}
		if (SMWidget.readyCb) SMWidget.readyCb();
	}
};

SMWidget['loadList'] = function () {
	if (SMWidget.tune && !SMWidget.tuneZoom()) return;
	SMWidget.tune = false;
	var l = [];
	var c = SMWidget.map.getCenter();
	var bo = SMWidget.map.getBounds();
	var n = 0;
	for (var i = 0; i < SMWidget.mks.length; i++) {
		var mk = SMWidget.mks[i];
		if (bo.contains(mk.getLatLng())) {
			n++;
			if (n > SMWidget.listMax) {
				smjq('#sm_widget_panel_div').html('Przybliż mapę, aby wyświetlić listę punktów');
				return;
			}
			var html = mk._popup.getContent();
			var id = smjq(html).find('button').attr('id');
			var t = id.split("_");
			var p = SMWidget.pts[t[1]];
			var b = null;
			if (SMWidget.apkt != t[1]) b = '<button id="l_' + t[1] + '" class="mapPoint__confirm sm_widget_l" type="button">Wybierz ten punkt</button>';
			else b = '<div id="sm_widget_tu">Odbieram tutaj</div>';
			var txt = '<div id="ldiv_' + t[1] + '" class="mapPoint sm_widget_ldiv"><div class="mapPoint__title">' + p.n +
				'</div><div class="mapPoint__street">' + p.a +
				(p.h ? '<br/>' + p.h : '') +
				'</div><div class="mapPoint__time">Otwarte: ' + p.o +
				'</div><div class="mapPoint__price">Koszt dostawy do punktu: ' + p.k +
				' zł</div>' + b + '</div>';
			var x = c.lat - p.la;
			var y = c.lng - p.lo;
			var d = x * x + y * y;
			l.push({
				d: d,
				txt: txt
			});
		}
	}
	l.sort(function (a, b) {
		return a.d - b.d
	});
	var l1 = [];
	var m = l.length;
	if (m > SMWidget.listLen) m = SMWidget.listLen;
	for (var i = 0; i < m; i++) l1.push(l[i].txt);
	var s = l1.join('');
	smjq('#sm_widget_panel_div').html(s);
	smjq('.sm_widget_l').click(function () {
		var id = smjq(this).attr('id');
		var pkt = SMWidget.id2pkt(id);
		var t = id.split("_");
		SMWidget.apkt = t[1];
		SMWidget.map.closePopup();
		SMWidget.genPopup(t[1]);
		SMWidget.loadList();
		if (SMWidget.selectCb) SMWidget.selectCb(pkt);
	});
	smjq('.sm_widget_ldiv').click(function () {
		var p = SMWidget.id2pkt(smjq(this).attr('id'))
		SMWidget.map.setView([p.la, p.lo], 15);
	});
	smjq('.sm_widget_ldiv').mouseenter(function () {
		var t = smjq(this).attr('id').split("_");
		SMWidget.mks_i[t[1]].setIcon(SMWidget.icon_b);
	});
	smjq('.sm_widget_ldiv').mouseleave(function () {
		var t = smjq(this).attr('id').split("_");
		SMWidget.mks_i[t[1]].setIcon(SMWidget.icon);
	});
}

SMWidget['showPts'] = function () {
	SMWidget.mks = [];
	SMWidget.mks_i = [];
	SMWidget.pts_ind = new Object();
	smjq('#loading-smmap').show();
	for (var i = 0; i < SMWidget.pts.length; i++) SMWidget.genPopup(i);
	smjq('#loading-smmap').hide();
	SMWidget.markerClusters.addLayers(SMWidget.mks);
}

SMWidget['genPopup'] = function (i) {
	var p = SMWidget.pts[i];
	if ((SMWidget.type.indexOf(p.t) == -1) || (SMWidget.type_st.indexOf(p.t) == -1)) return;
	SMWidget.pts_ind[p.n.toUpperCase()] = p;
	var b;
	if (SMWidget.apkt != i) {
		b = '<button id="p_' + i + '" class="sm_widget_p" type="button">Wybierz ten punkt</button>';
		if (p.k != null) b += '<button id="p_' + i + '_b" class="sm_widget_k1" style="display: none;" type="button">Koszt dostawy do punktu: ' + p.k + ' zł</button>';
	}
	else {
		b = '<button id="p_' + i + '_b" class="sm_widget_k1" type="button">Wybrano punkt</button>';			
	}
	var popup = '<div class="mapPoint_popup" id="pdiv_' + i + '"><b>' + p.n +
		'</b><br/>' + p.a +
		(p.h ? '<br/>' + p.h : '') +
		'<br/>Otwarte: ' + p.o +
		'<br/><br/>' + b + '</div>';
	var m;
	if(SMWidget.mks_i[i]) m = SMWidget.mks_i[i];
	else {
		m = L.marker([p.la, p.lo], {
			icon: SMWidget.icon
		});		
		SMWidget.mks.push(m);
		SMWidget.mks_i[i] = m;	
	}
	m.bindPopup(popup);
}

SMWidget['popopen'] = function (e) {
	var html = e.popup.getContent();
	var id = smjq(html).find('button').attr('id');
	var t = id.split("_");
	SMWidget.loadList();
	smjq('.sm_widget_p').click(function () {
		var id = smjq(this).attr('id');
		smjq(this).hide();
		smjq('#' + id + '_b').show();
		var pkt = SMWidget.id2pkt(id);
		var t = id.split("_");
		SMWidget.apkt = t[1];
		SMWidget.genPopup(t[1]);
		SMWidget.loadList();
		if (SMWidget.selectCb) SMWidget.selectCb(pkt);
	});
};

SMWidget['popclose'] = function (e) {
	SMWidget.loadList();
};

SMWidget['id2pkt'] = function (pid) {
	var t = pid.split("_");
	return SMWidget.pts[t[1]];
};

SMWidget['updateProgressBar'] = function (processed, total, elapsed, layersArray) {
	if (elapsed > 100) {
		smjq('#progress').show();
		smjq('#progress-bar').width(Math.round(processed / total * 100) + '%');
	}
	if (processed == total) {
		smjq('#progress').hide();
		setTimeout(SMWidget.loadList, 100);
	}
};

SMWidget['loadPts'] = function (cb) {
	var d = {a: 'g', cod: SMWidget.cod};
	smjq.ajax({
		success: cb,
		type: 'POST',
		url: SMWidget.url,
		dataType: "json",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(d),
		complete: function () {
			smjq('#loading-smmap').hide();
		},
	});
};

SMWidget['search'] = function () {
	var v = smjq('#sm_widget_inp').val();
	if ((SMWidget.pts_ind != null) && (SMWidget.pts_ind[v.toUpperCase()] != null)) {
		var p = SMWidget.pts_ind[v.toUpperCase()];
		SMWidget.map.setView([p.la, p.lo], 15);
		return;
	}
	SMWidget.provider.search({
		query: v
	}).then((results) => {
		if (SMWidget.searchMark != null) SMWidget.searchMark.remove(SMWidget.map);
		if (results.length == 0) alert('Nie znaleziono');
		else {
			var r = results[0];
			var popup = r.label;
			var m = L.marker([r.y, r.x]).bindPopup(popup).addTo(SMWidget.map);
			SMWidget.searchMark = m;
			SMWidget.tune = true;
			SMWidget.map.setView([r.y, r.x], 15);
		}
	})
};

SMWidget['toggleType'] = function (t) {
	if (SMWidget.type_st.indexOf(t) != -1) SMWidget.type_st.splice(SMWidget.type_st.indexOf(t), 1);
	else SMWidget.type_st.push(t);
};

SMWidget['buttonFilter'] = function (i) {
	SMWidget.toggleType(SMWidget.type_i[i - 1]);
	SMWidget.showButtons();
	SMWidget.markerClusters.clearLayers();
	SMWidget.showPts();
}

SMWidget['genButtons'] = function () {
	var s = '<table class="sm_widget_f">';
	var n = 1;
	for (var i = 1; i <= 9; i++) {
		var t = SMWidget.type_i[i - 1];
		if (SMWidget.type.indexOf(t) != -1) {
			if (n % 3 == 1) s += '<tr>';
			s += '<td><button onclick="SMWidget.buttonFilter(' + i + ')" id="sm_widget_but_f' + i + '" class="sm_widget_p1" type="button">' + SMWidget.type_t[i - 1] + '</button></td>';
			if (n % 3 == 0) s += '</tr>';
			n++;
		}
	}
	if (n % 3 != 0) s += '</tr>';
	s += '</table>';
	return s;
}

SMWidget['buttonAll'] = function (i) {
	for (var i = 1; i <= 9; i++) {
		var t = SMWidget.type_i[i - 1];
		if (SMWidget.type_st.indexOf(t) == -1) SMWidget.toggleType(t);
	}
	SMWidget.showButtons();
	SMWidget.markerClusters.clearLayers();
	SMWidget.showPts();
}

SMWidget['showButtons'] = function () {
	for (var i = 1; i <= 9; i++) {
		var t = SMWidget.type_i[i - 1];
		if (SMWidget.type_st.indexOf(t) != -1) {
			smjq('#sm_widget_but_f' + i).addClass('sm_widget_p1');
			smjq('#sm_widget_but_f' + i).removeClass('sm_widget_p2');
		} else {
			smjq('#sm_widget_but_f' + i).addClass('sm_widget_p2');
			smjq('#sm_widget_but_f' + i).removeClass('sm_widget_p1');
		}
	}
}

SMWidget['show'] = function (t) {
	if (typeof t === 'undefined') {
		t = 400;
	}
	if (t == 0) smjq('#' + SMWidget.el).show();
	else smjq('#' + SMWidget.el).slideDown(t);
	SMWidget.map.invalidateSize();
};

SMWidget['hide'] = function (t) {
	if (typeof t === 'undefined') {
		t = 400;
	}
	if (t == 0) smjq('#' + SMWidget.el).hide();
	else smjq('#' + SMWidget.el).slideUp(t);
};

SMWidget['getLocation'] = function () {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(SMWidget.showPosition);
	} else {
		alert('Funkcja nie obsługiwana przez przeglądarkę');
	}
}

SMWidget['showPosition'] = function (p) {
	if (SMWidget.searchMark != null) SMWidget.searchMark.remove(SMWidget.map);
	var m = L.marker([p.coords.latitude, p.coords.longitude]).addTo(SMWidget.map);
	SMWidget.searchMark = m;
	SMWidget.tune = true;
	SMWidget.map.setView([p.coords.latitude, p.coords.longitude], 15);
}

SMWidget['tuneZoom'] = function () {
	var l = 0;
	var b = SMWidget.map.getBounds();
	for (var i = 0; i < SMWidget.mks.length; i++) {
		var mk = SMWidget.mks[i];
		if (b.contains(mk.getLatLng())) l++;
	}
	var z = SMWidget.map.getZoom();
	if (l > 0) return true;
	if (z < 8) return true;
	SMWidget.map.zoomOut(1);
	return false;
}